h1 {
    font-size: 2.8rem;
    font-weight: 800 !important;
    line-height: 2.0;
    font-family: Arial, Helvetica, sans-serif;
    /* font-family: 'Playfair Display', serif; */
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.1;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
}

p {
    font-size: 18px;
    margin: 20px auto;
    text-align: left;
    line-height: 1.90rem;
    letter-spacing: normal;
    color: #333;
}

li {
    padding: 5px 0px;
}

button {
    width: 100%;
    color: #fff;
    border-radius: 4px;
    font-weight: bold;
    display: block;
    border: none;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
}

@media (max-width: 600px) {
    p {
        font-size: 20px;
        margin: 20px auto;
        text-align: left;
        font-weight: 300;
        line-height: 1.90rem;
        letter-spacing: normal;
        color: #333;
    }
    .offer_post h2, h2 {
        line-height: 35px !important;
        font-size: 25px !important;
        text-transform: capitalize !important;
    }
    
}
